import { useState } from "react"
import { Link } from 'react-router-dom'

export default function Header() {

    const klass = "navBar"
    const [open, setOpen] = useState(false)

    return <div className="row u-full-width nav">
        <div className="container" style={{ padding: "unset" }}>
            <div className={open ? `${klass} responsive` : klass}>
                <Link to="/">
                    <img style={{ verticalAlign: "middle", marginLeft: "-4px" }}
                        width="auto" alt="Motormax"
                        height="36" src="https://storage.googleapis.com/static_mmxfront/logo.png" />
                </Link>
                <Link to="/about-us">
                    Quiénes somos
                </Link>
                <Link to="https://motormax.com.ar/team">
                    Equipo
                </Link>
                <Link to="/" className="icon" onClick={e => { e.preventDefault(); setOpen(v => !v) }}>&#9776;</Link>
            </div>
        </div>
    </div>

}


