import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom'
import Layout from './components/Layout'
import Loading from './components/Loading';

const AboutUs = lazy(() => import('./pages/AboutUs'));
const Staff = lazy(() => import('./pages/Staff'));
const Home = lazy(() => import('./pages/Home'));

export default function App() {

  return <Routes>
    <Route path="/" element={<Layout />}>
      <Route index element={<Suspense fallback={<Loading />}><Home /></Suspense>} />
      <Route path="/about-us" element={<Suspense fallback={<Loading />}><AboutUs /></Suspense>} />
      <Route path="/staff" element={<Suspense fallback={<Loading />}><Staff /></Suspense>} />
    </Route>
  </Routes>
}