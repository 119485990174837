import { BiLogoInstagram, BiLogoFacebookSquare, BiLogoWhatsapp, BiLogoTwitter } from "react-icons/bi";

export default function Footer() {

    const social = [
        { link: "https://www.instagram.com/motormax.com.ar", icon: <BiLogoInstagram className="icon" /> },
        { link: "https://twitter.com/motormax_com_ar", icon: <BiLogoTwitter className="icon" /> },
        { link: "https://www.facebook.com/motormax.com.ar", icon: <BiLogoFacebookSquare className="icon" /> },
        { link: "https://wa.me/+5491133355000?text=Hola%20Motormax!", icon: <BiLogoWhatsapp className="icon" /> }
    ]

    return <div className="row u-full-width footer">
        <div className="container">
            <div className="row">
                <div className="one-third column">
                    <img alt="Motormax" src="https://storage.googleapis.com/static_mmxfront/logowk.webp" height="30" />
                </div>
                <div className="one-third column">
                    <a href="https://motormax.com.ar/tos">Política de privacidad</a><br /><a href="https://motormax.com.ar/tos">Términos y condiciones</a>
                </div>
                <div className="one-third column">
                    <div className="social-icons">
                        {social.map((item, i) => <a href={item.link}>{item.icon}</a>)}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="column">
                    <span style={{ color: "gray", display: "block", paddingTop: "16px", fontSize:"75%" }}>PIMENTO SRL CUIT 30714273090<br />Esteban Echeverria 3750, Vicente Lopez<br/>Provincia de Buenos Aires</span>
                </div>
            </div>
        </div>
    </div>

}


