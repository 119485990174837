import Footer from './Footer'
import { Outlet } from 'react-router-dom'
import Header from './Header'
import Whatsapp from './Whatsapp'

export default function Layout() {

    return (
        <>
            <Header />
            <div className="container">
                <Outlet />
            </div>
            <Footer />
            <Whatsapp />
        </>
    )
}

