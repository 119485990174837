import wa from '../whatsapp.png';
import phone from '../phone.svg'

export default function Whatsapp() {
    return <>
        <a aria-label="Motormax" rel="noreferrer" className='phone'
            target='_blank' title='Llamanos!' href="tel:+5491133355000">
            <img style={{ marginTop: 16 }} width="32" height="auto" alt='Motormax Teléfono' src={phone} />
        </a>
        <a aria-label="Chat on WhatsApp" rel="noreferrer" className='wa'
            target='_blank' title='Motormax Whatsapp' href="https://wa.me/+5491133355000?text=Hola%20Motormax!">
            <img width="64" height="auto" alt='Motormax Whatsapp' src={wa} />
        </a>
    </>

}
